<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 occupancy-screen reports-screen technical-list-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="occupancy-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("devices.header.title") }}
        </div>
      </top-header-menu-wrapper>
      <search
        v-if="search"
        @changedInput="modifySearchQuery"
        @clear="clearSearchQuery"
        @startSearch="getData(false, true)"
        :hasFilters="true"
        :filters="filters"
        routeName="r_occupancy-technical-list-search-filters"
      ></search>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreenItem }}/{{ paginationData.total }}</span
        >
      </div>
    </template>
    <div v-if="filtersChanged" class="filters-changed-overlay"></div>
    <ul v-if="filtersChanged" class="clebex-item-section search-refresh">
      <li class="clebex-item-section-item no-bottom-border">
        <div class="clebex-item-content-wrapper">
          <div class="clebex-section-input">
            <label class="clebex-section-input-label">{{
              displayLabelName("occupancy", "dashboard", "refresh")
            }}</label>
          </div>
          <span @click="getData(true, true)" class="follow-up-icon-item">
            <icon icon="#cx-foo1-refresh-28x28" />
          </span>
        </div>
      </li>
    </ul>
    <div
      style="width: 100%; color: #999999; padding: 20px; padding-bottom: 0px; text-align: left;"
      v-if="devices && devices.display_devices"
    >
      <span>{{
        displayLabelName("devices", "header", "display-devices")
      }}</span>
    </div>
    <ul
      class="clebex-item-section technical-list technical-list-header"
      :class="{
        'filters-open':
          $route.name === 'r_occupancy-technical-list-search-filters'
      }"
    >
      <li
        v-if="devices && devices.display_devices"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "manufacturer") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "resource-name") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "location") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "tokens") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "last-pullout") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "assigned-user") }}
          </div>
          <div class="clebex-item-column"></div>
        </div>
      </li>
    </ul>
    <ul
      v-if="devices"
      ref="devices"
      @scroll="handleScroll"
      class="clebex-item-section technical-list"
      style="margin-top: 0px;"
      :class="{
        'filters-open':
          $route.name === 'r_occupancy-technical-list-search-filters'
      }"
    >
      <li
        v-for="item in devices.display_devices"
        :key="item.id"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-item-column" style="width: 100%">
            <span
              >{{
                displayLabelName("devices", "header", "manufacturer")
              }}:</span
            >
            {{ item.manufacturer }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            <span
              >{{
                displayLabelName("devices", "header", "resource-name")
              }}:</span
            >
            {{ item.resource_name }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            <span
              >{{ displayLabelName("devices", "header", "location") }}:</span
            >
            {{ item.level }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            <span>{{ displayLabelName("devices", "header", "tokens") }}:</span>
            {{ item.tokens }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            <span
              >{{
                displayLabelName("devices", "header", "last-pullout")
              }}:</span
            >
            {{ item.heartbeat_at ? item.heartbeat_at : "N/A" }}
          </div>
          <div
            @click="openUsers(item)"
            class="clebex-item-column"
            style="width: 100%; cursor: pointer;"
          >
            <span
              >{{ displayLabelName("devices", "header", "assigned-user") }}:
            </span>
            <p v-for="user in item.assigned_user" :key="user.id">
              {{ user.first_name }} {{ user.last_name }}
            </p>
          </div>
          <div
            @click="openUsers(item)"
            class="clebex-item-column"
            style="cursor: pointer;"
          >
            <icon
              icon="#cx-app1-arrow-right-12x12"
              width="12"
              height="12"
              :style="[
                selectedDevice && item.id == selectedDevice.id
                  ? '--color-2: #24b7af;'
                  : ''
              ]"
            ></icon>
          </div>
        </div>
      </li>
    </ul>
    <div
      style="width: 100%; color: #999999; padding: 20px; padding-bottom: 0px; text-align: left;"
      v-if="devices && devices.display_devices"
    >
      <span>{{
        displayLabelName("devices", "header", "reception-desks")
      }}</span>
    </div>
    <ul
      class="clebex-item-section technical-list technical-list-header"
      :class="{
        'filters-open':
          $route.name === 'r_occupancy-technical-list-search-filters'
      }"
    >
      <li
        v-if="devices && devices.reception_desks"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "manufacturer") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "resource-name") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "location") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "tokens") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "last-pullout") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "assigned-user") }}
          </div>
          <div class="clebex-item-column"></div>
        </div>
      </li>
    </ul>
    <ul
      v-if="devices"
      ref="receptiondesks"
      @scroll="handleScroll"
      class="clebex-item-section technical-list"
      style="margin-top: 0px;"
      :class="{
        'filters-open':
          $route.name === 'r_occupancy-technical-list-search-filters'
      }"
    >
      <li
        v-for="item in devices.reception_desks"
        :key="item.id"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-item-column" style="width: 100%">
            <span
              >{{
                displayLabelName("devices", "header", "manufacturer")
              }}:</span
            >
            N/A
          </div>
          <div class="clebex-item-column" style="width: 100%">
            <span>{{ displayLabelName("devices", "header", "name") }}:</span>
            {{ item.name }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            <span
              >{{ displayLabelName("devices", "header", "location") }}:</span
            >
            {{ item.path }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            <span>{{ displayLabelName("devices", "header", "tokens") }}:</span>
            {{ item.tokens }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            <span
              >{{
                displayLabelName("devices", "header", "last-pullout")
              }}:</span
            >
            {{ item.heartbeat_at ? item.heartbeat_at : "N/A" }}
          </div>
          <div
            @click="openUsers(item)"
            class="clebex-item-column"
            style="width: 100%; cursor: pointer;"
          >
            <span
              >{{ displayLabelName("devices", "header", "assigned-user") }}:
            </span>
            <p v-for="user in item.assigned_user" :key="user.id">
              {{ user.first_name }} {{ user.last_name }}
            </p>
          </div>
          <div
            @click="openUsers(item)"
            class="clebex-item-column"
            style="cursor: pointer;"
          >
            <icon
              icon="#cx-app1-arrow-right-12x12"
              width="12"
              height="12"
              :style="[
                selectedDevice && item.id == selectedDevice.id
                  ? '--color-2: #24b7af;'
                  : ''
              ]"
            ></icon>
          </div>
        </div>
      </li>
    </ul>
    <div
      style="width: 100%; color: #999999; padding: 20px; padding-bottom: 0px; text-align: left;"
      v-if="devices && devices.display_devices"
    >
      <span>{{
        displayLabelName("devices", "header", "realtime-devices")
      }}</span>
    </div>
    <ul
      class="clebex-item-section technical-list technical-list-header"
      :class="{
        'filters-open':
          $route.name === 'r_occupancy-technical-list-search-filters'
      }"
    >
      <li
        v-if="devices && devices.realtime_devices"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "manufacturer") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "resource-name") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "location") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "tokens") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "last-pullout") }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            {{ displayLabelName("devices", "header", "assigned-user") }}
          </div>
          <div class="clebex-item-column"></div>
        </div>
      </li>
    </ul>
    <ul
      v-if="devices"
      ref="realtime"
      @scroll="handleScroll"
      class="clebex-item-section technical-list"
      style="margin-top: 0px;"
      :class="{
        'filters-open':
          $route.name === 'r_occupancy-technical-list-search-filters'
      }"
    >
      <li
        v-for="item in devices.realtime_devices"
        :key="item.id"
        class="clebex-item-section-item"
      >
        <div class="clebex-item-content-wrapper">
          <div class="clebex-item-column" style="width: 100%">
            <span
              >{{
                displayLabelName("devices", "header", "manufacturer")
              }}:</span
            >
            N/A
          </div>
          <div class="clebex-item-column" style="width: 100%">
            <span>{{ displayLabelName("devices", "header", "name") }}:</span>
            {{ item.name }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            <span
              >{{ displayLabelName("devices", "header", "location") }}:</span
            >
            {{ item.path }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            <span>{{ displayLabelName("devices", "header", "tokens") }}:</span>
            {{ item.tokens }}
          </div>
          <div class="clebex-item-column" style="width: 100%">
            <span
              >{{
                displayLabelName("devices", "header", "last-pullout")
              }}:</span
            >
            {{ item.heartbeat_at ? item.heartbeat_at : "N/A" }}
          </div>
          <div
            @click="openUsers(item)"
            class="clebex-item-column"
            style="width: 100%; cursor: pointer;"
          >
            <span
              >{{ displayLabelName("devices", "header", "assigned-user") }}:
            </span>
            <p v-for="user in item.assigned_user" :key="user.id">
              {{ user.first_name }} {{ user.last_name }}
            </p>
          </div>
          <div
            @click="openUsers(item)"
            class="clebex-item-column"
            style="cursor: pointer;"
          >
            <icon
              icon="#cx-app1-arrow-right-12x12"
              width="12"
              height="12"
              :style="[
                selectedDevice && item.id == selectedDevice.id
                  ? '--color-2: #24b7af;'
                  : ''
              ]"
            ></icon>
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <occupancy-footer-menu></occupancy-footer-menu>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";

export default {
  name: "Devices",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    Search: defineAsyncComponent(() => import("@/components/search/Search"))
  },
  data() {
    return {
      search: null,
      topScreenItem: 1,
      itemHeight: null,
      itemListTop: null,
      paginationCountActive: false,
      timeout: null
    };
  },
  created() {
    this.getDevices();
    if (!this.isMobileDevice()) {
      this.$store.commit("global/setIsFullWidthScreen", true, {
        root: true
      });
    }
  },
  computed: {
    ...mapState("devices", ["devices", "selectedDevice"])
  },
  methods: {
    ...mapActions("devices", ["getDevices", "selectDevice", "getUsers"]),
    openUsers(device) {
      this.selectDevice(device);
      if (this.$router.name == "r_devices-users") {
        this.$router.go();
      } else {
        this.$router.push({
          name: "r_devices-users"
        });
      }
    }
  },
  watch: {
    selectedDevice(value) {
      if (value == null) {
        this.getDevices();
      }
    }
  }
};
</script>
